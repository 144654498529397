var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"newReclamationForm"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('NEW_RECLAMATION_TITLE')))]),_c('hr')]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_TYPE')}},[_c('v-select',{attrs:{"options":_vm.reclamationType,"clearable":false},on:{"input":_vm.updateType},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}]),model:{value:(_vm.reclamation.reclamationType),callback:function ($$v) {_vm.$set(_vm.reclamation, "reclamationType", $$v)},expression:"reclamation.reclamationType"}}),(_vm.errorsField.type !== '')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorsField.type))+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_SUBTYPE')}},[_c('v-select',{attrs:{"options":_vm.reclamationSubTypeByType,"clearable":false,"disabled":_vm.reclamation.reclamationSubType === '' && _vm.reclamation.reclamationType === ''},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}]),model:{value:(_vm.reclamation.reclamationSubType),callback:function ($$v) {_vm.$set(_vm.reclamation, "reclamationSubType", $$v)},expression:"reclamation.reclamationSubType"}}),(_vm.errorsField.subType !== '')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorsField.subType))+" ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_EXTERNAL_DOC_NUMBER')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('PLACEHOLDER_EXTERNAL_DOC_NUMBER')},model:{value:(_vm.reclamation.externalDocNumber),callback:function ($$v) {_vm.$set(_vm.reclamation, "externalDocNumber", $$v)},expression:"reclamation.externalDocNumber"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.isBetrancourt)?_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY')}},[_c('v-select',{attrs:{"options":_vm.companies,"clearable":false},on:{"input":_vm.updateUserCompany},model:{value:(_vm.reclamation.company),callback:function ($$v) {_vm.$set(_vm.reclamation, "company", $$v)},expression:"reclamation.company"}}),(_vm.errorsField.company !== '')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorsField.company))+" ")]):_vm._e()],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.isBetrancourt)?_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_USER_CLIENT')}},[_c('v-select',{attrs:{"options":_vm.usersCompany,"name":"userClient"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}],null,false,2871847309),model:{value:(_vm.reclamation.userClient),callback:function ($$v) {_vm.$set(_vm.reclamation, "userClient", $$v)},expression:"reclamation.userClient"}}),(_vm.errorsField.userClient !== '')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorsField.userClient))+" ")]):_vm._e()],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_TITLE'),"label-for":"title"}},[_c('validation-provider',{attrs:{"name":_vm.$t('RECLAMATION_TITLE'),"rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"title"},model:{value:(_vm.reclamation.title),callback:function ($$v) {_vm.$set(_vm.reclamation, "title", $$v)},expression:"reclamation.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_DETAIL')}},[_c('b-form-textarea',{attrs:{"type":"text","rows":"10","placeholder":_vm.$t('PLACEHOLDER_RECLAMATION_DETAIL')},model:{value:(_vm.reclamation.detail),callback:function ($$v) {_vm.$set(_vm.reclamation, "detail", $$v)},expression:"reclamation.detail"}})],1),(_vm.errorsField.detail)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.errorsField.detail)))]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-dropzone',{ref:"dropzone",attrs:{"id":"fileUpload","options":_vm.dropOptions,"use-custom-slot":true},on:{"vdropzone-file-added":_vm.afterComplete,"vdropzone-removed-file":_vm.afterRemove}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('DRAG_DROP_PLACEHOLDER'))+" ")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('DRAG_DROP_AUTHORIZED_FILES')))])])])],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('RECLAMATION_NOTIFY_USERS')))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_CONTACT_1'),"label-for":"contact1"}},[_c('v-select',{attrs:{"options":_vm.getNotifiedUser('contact1'),"name":"contact1"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}]),model:{value:(_vm.reclamation.contact1),callback:function ($$v) {_vm.$set(_vm.reclamation, "contact1", $$v)},expression:"reclamation.contact1"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_CONTACT_2'),"label-for":"contact2"}},[_c('v-select',{attrs:{"options":_vm.getNotifiedUser('contact2'),"name":"contact2"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}]),model:{value:(_vm.reclamation.contact2),callback:function ($$v) {_vm.$set(_vm.reclamation, "contact2", $$v)},expression:"reclamation.contact2"}})],1)],1)],1),(_vm.isBetrancourt)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('RECLAMATION_NOTIFY_TECHNICIAN')))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_NOTIFIED_USER_1'),"label-for":"notifiedUser1"}},[_c('v-select',{attrs:{"options":_vm.getNotifiedUser2('notifiedUser1'),"name":"notifiedUser1"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}],null,false,2871847309),model:{value:(_vm.reclamation.notifiedUser1),callback:function ($$v) {_vm.$set(_vm.reclamation, "notifiedUser1", $$v)},expression:"reclamation.notifiedUser1"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('RECLAMATION_NOTIFIED_USER_2'),"label-for":"notifiedUser2"}},[_c('v-select',{attrs:{"options":_vm.getNotifiedUser2('notifiedUser2'),"name":"notifiedUser2"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}],null,false,2871847309),model:{value:(_vm.reclamation.notifiedUser2),callback:function ($$v) {_vm.$set(_vm.reclamation, "notifiedUser2", $$v)},expression:"reclamation.notifiedUser2"}})],1)],1)],1):_vm._e(),(_vm.canSend())?_c('b-row',[_c('b-col',{attrs:{"offset-md":"4"}},[(_vm.loading)?_c('b-button',{attrs:{"variant":"primary","type":"button","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t('LOADING_BUTTON'))+" ... ")],1):_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.submitReclamation}},[_vm._v(" "+_vm._s(_vm.$t('SEND_RECLAMATION'))+" ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }