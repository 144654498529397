<template>
  <b-card>
    <b-form @submit.prevent>
      <validation-observer ref="newReclamationForm">
        <b-row>
          <b-col md="12">
            <h4>{{ $t('NEW_RECLAMATION_TITLE') }}</h4>
            <hr>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_TYPE')"
            >
              <v-select
                v-model="reclamation.reclamationType"
                :options="reclamationType"
                :clearable="false"
                @input="updateType"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
                <template #selected-option="{label}">
                  {{ $t(label) }}
                </template>
              </v-select>
              <small
                v-if="errorsField.type !== ''"
                class="text-danger"
              >
                {{ $t(errorsField.type) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_SUBTYPE')"
            >
              <v-select
                v-model="reclamation.reclamationSubType"
                :options="reclamationSubTypeByType"
                :clearable="false"
                :disabled="reclamation.reclamationSubType === '' && reclamation.reclamationType === ''"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
                <template #selected-option="{label}">
                  {{ $t(label) }}
                </template>
              </v-select>
              <small
                v-if="errorsField.subType !== ''"
                class="text-danger"
              >
                {{ $t(errorsField.subType) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_EXTERNAL_DOC_NUMBER')"
            >
              <b-form-input
                v-model="reclamation.externalDocNumber"
                :placeholder="$t('PLACEHOLDER_EXTERNAL_DOC_NUMBER')"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="isBetrancourt"
              :label="$t('COMPANY')"
            >
              <v-select
                v-model="reclamation.company"
                :options="companies"
                :clearable="false"
                @input="updateUserCompany"
              />
              <small
                v-if="errorsField.company !== ''"
                class="text-danger"
              >
                {{ $t(errorsField.company) }}
              </small>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              v-if="isBetrancourt"
              :label="$t('RECLAMATION_USER_CLIENT')"
            >
              <v-select
                v-model="reclamation.userClient"
                :options="usersCompany"
                name="userClient"
              >
                <template #option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
                <template #selected-option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
              </v-select>
              <small
                v-if="errorsField.userClient !== ''"
                class="text-danger"
              >
                {{ $t(errorsField.userClient) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('RECLAMATION_TITLE')"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('RECLAMATION_TITLE')"
                rules="required|min:10"
              >
                <b-form-input
                  v-model="reclamation.title"
                  name="title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('RECLAMATION_DETAIL')"
            >
              <b-form-textarea
                v-model="reclamation.detail"
                type="text"
                rows="10"
                :placeholder="$t('PLACEHOLDER_RECLAMATION_DETAIL')"
              />
            </b-form-group>
            <small
              v-if="errorsField.detail"
              class="text-danger"
            >{{ $t(errorsField.detail) }}</small>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <vue-dropzone
              id="fileUpload"
              ref="dropzone"
              :options="dropOptions"
              :use-custom-slot="true"
              @vdropzone-file-added="afterComplete"
              @vdropzone-removed-file="afterRemove"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{ $t('DRAG_DROP_PLACEHOLDER') }}
                </h3><br>
                <span>{{ $t('DRAG_DROP_AUTHORIZED_FILES') }}</span>
              </div>

            </vue-dropzone>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col md="12">
            <h4>{{ $t('RECLAMATION_NOTIFY_USERS') }}</h4>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_CONTACT_1')"
              label-for="contact1"
            >
              <v-select
                v-model="reclamation.contact1"
                :options="getNotifiedUser('contact1')"
                name="contact1"
              >
                <template #option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
                <template #selected-option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_CONTACT_2')"
              label-for="contact2"
            >
              <v-select
                v-model="reclamation.contact2"
                :options="getNotifiedUser('contact2')"
                name="contact2"
              >
                <template #option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
                <template #selected-option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="isBetrancourt">
          <b-col md="12">
            <h4>{{ $t('RECLAMATION_NOTIFY_TECHNICIAN') }}</h4>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_NOTIFIED_USER_1')"
              label-for="notifiedUser1"
            >
              <v-select
                v-model="reclamation.notifiedUser1"
                :options="getNotifiedUser2('notifiedUser1')"
                name="notifiedUser1"
              >
                <template #option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
                <template #selected-option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RECLAMATION_NOTIFIED_USER_2')"
              label-for="notifiedUser2"
            >
              <v-select
                v-model="reclamation.notifiedUser2"
                :options="getNotifiedUser2('notifiedUser2')"
                name="notifiedUser2"
              >
                <template #option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
                <template #selected-option="{firstName, lastName}">
                  {{ firstName }} {{ lastName }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="canSend()">
          <b-col offset-md="4">
            <!-- submit buttons -->
            <b-button
              v-if="loading"
              variant="primary"
              type="button"
              disabled
            >
              <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mr-1"
              variant="primary"
              @click="submitReclamation"
            >
              {{ $t('SEND_RECLAMATION') }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vueDropzone from 'vue2-dropzone'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vueDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: false,
      errorShow: false,
      errorInsert: '',
      selectedCompany: null,
      isBetrancourt: false,
      errorsField: {
        type: null,
        subType: null,
        company: null,
        userClient: null,
        detail: null,
      },
      reclamation: {
        title: '',
        detail: '',
        company: '',
        externalDocNumber: '',
        userClient: '',
        contact1: '',
        contact2: '',
        reclamationType: '',
        reclamationSubType: '',
        notifiedUser1: '',
        notifiedUser2: '',
      },
      reclamationFiles: [],
      files: [],
      dropOptions: {
        autoProcessQueue: false,
        url: 'http://localhost/',
        maxFilesize: 2,
        maxFiles: 5,
        chunking: true,
        chunkSize: 500,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: 'image/*,.xlsx,.xls,.pdf,.doc,.docx, .zip',
      },
    }
  },
  computed: {
    reclamationType() {
      return this.$store.getters['reclamationType/getTypes']
    },
    reclamationSubType() {
      return this.$store.getters['reclamationSubType/getSubTypes']
    },
    reclamationSubTypeByType() {
      return this.$store.getters['reclamationSubType/getSubTypesByType']
    },
    usersCompany() {
      let users = this.$store.getters['companies/getUsersByCompany']
      users = users.filter(user => {
        if (this.reclamation.userClient) {
          if (user.id !== this.reclamation.userClient.id && user.id !== JSON.parse(localStorage.userData).id) {
            return true
          }
        } else if (user.id !== JSON.parse(localStorage.userData).id) {
          return true
        }
        return false
      })
      return users
    },
    usersBetrancourt() {
      const users = this.$store.getters['users/getUsersBetrancourt']
      return users.filter(user => user.id !== JSON.parse(localStorage.userData).id)
    },
    companies() {
      return this.$store.getters['companies/getCompanies']
    },
    locale() {
      return this.$i18n.locale
    },
  },
  mounted() {
    this.$store.dispatch('reclamationType/fetchAll')
    this.$store.dispatch('reclamationSubType/fetchAll')
    if (JSON.parse(localStorage.userData).company) {
      this.$store.dispatch('companies/fetchAll').then(r => {
        // eslint-disable-next-line prefer-destructuring
        this.reclamation.company = r[0]
        this.$store.dispatch('companies/fetchAllUsersByCompany', r[0])
      })
    } else {
      this.isBetrancourt = true
      this.$store.dispatch('companies/fetchAll')
      this.$store.dispatch('users/fetchAllUsersBetrancourt')
    }
  },
  methods: {
    getNotifiedUser2(user) {
      const userExclure = []
      const users = this.usersBetrancourt
      if (user === 'notifiedUser1') {
        if (this.reclamation.userClient !== '' && this.reclamation.userClient !== null) userExclure.push(this.reclamation.userClient['@id'])
        if (this.reclamation.notifiedUser2 !== '' && this.reclamation.notifiedUser2 !== null) userExclure.push(this.reclamation.notifiedUser2['@id'])
      }
      if (user === 'notifiedUser2') {
        if (this.reclamation.notifiedUser1 !== '' && this.reclamation.notifiedUser1 !== null) userExclure.push(this.reclamation.notifiedUser1['@id'])
        if (this.reclamation.userClient !== '' && this.reclamation.userClient !== null) userExclure.push(this.reclamation.userClient['@id'])
      }
      return users.filter(el => !userExclure.includes(el['@id']))
    },
    getNotifiedUser(contact) {
      const userExclure = []
      const users = this.usersCompany
      if (contact === 'userClient') {
        if (this.reclamation.contact1 !== '' && this.reclamation.contact1 !== null) userExclure.push(this.reclamation.contact1['@id'])
        if (this.reclamation.contact2 !== '' && this.reclamation.contact2 !== null) userExclure.push(this.reclamation.contact2['@id'])
      }
      if (contact === 'contact1') {
        if (this.reclamation.userClient !== '' && this.reclamation.userClient !== null) userExclure.push(this.reclamation.userClient['@id'])
        if (this.reclamation.contact2 !== '' && this.reclamation.contact2 !== null) userExclure.push(this.reclamation.contact2['@id'])
      }
      if (contact === 'contact2') {
        if (this.reclamation.contact1 !== '' && this.reclamation.contact1 !== null) userExclure.push(this.reclamation.contact1['@id'])
        if (this.reclamation.userClient !== '' && this.reclamation.userClient !== null) userExclure.push(this.reclamation.userClient['@id'])
      }
      return users.filter(el => !userExclure.includes(el['@id']))
    },
    updateType() {
      if (this.reclamation.reclamationType && this.reclamation.reclamationType.uri) {
        this.$store.dispatch('reclamationSubType/fetchAllByType', this.reclamation.reclamationType.uri)
      }
      this.reclamation.reclamationSubType = ''
    },
    updateUserCompany() {
      if (this.reclamation.company && this.reclamation.company.users) {
        this.$store.dispatch('companies/fetchAllUsersByCompany', this.reclamation.company)
      }
    },
    uploadFile(e) {
      const selectedFile = e.target.files
      if (!selectedFile.length) return false

      for (let i = 0; i < selectedFile.length; i += 1) {
        this.reclamationFiles.push(selectedFile[i])
      }
      return true
    },
    canSend() {
      if (this.reclamation.title !== '' && this.reclamation.detail !== '' && this.reclamation.reclamationType !== '' && this.reclamation.reclamationSubType !== '') {
        if (this.isBetrancourt) {
          if (this.reclamation.company !== '' && this.reclamation.userClient !== '') {
            return true
          }
          return false
        }
        return true
      }
      return false
    },
    controlleOtherChamps() {
      this.errorsField = {
        type: null,
        subType: null,
        company: null,
        userClient: null,
      }
      if (this.reclamation.reclamationType === '') this.errorsField.type = 'FIELD_REQUIRED'
      if (this.reclamation.reclamationSubType === '') this.errorsField.subType = 'FIELD_REQUIRED'

      if (this.isBetrancourt) {
        if (this.reclamation.company === '') this.errorsField.company = 'FIELD_REQUIRED'
        if (this.reclamation.userClient === '') this.errorsField.userClient = 'FIELD_REQUIRED'
      }

      if (this.reclamation.detail === '' || this.reclamation.detail.length < 10) this.errorsField.detail = 'FIELD_REQUIRED'

      if (this.errorsField.type !== null) return false
      if (this.errorsField.subType !== null) return false
      if (this.errorsField.company !== null) return false
      if (this.errorsField.userClient !== null) return false
      return true
    },
    submitReclamation() {
      const that = this
      this.loading = true

      this.$refs.newReclamationForm.validate().then(success => {
        if (success) {
          if (this.controlleOtherChamps()) {
            const form = new FormData()
            if (that.reclamationFiles.length) {
              for (let i = 0; i < that.reclamationFiles.length; i += 1) {
                form.append(`files[${i}]`, that.reclamationFiles[i])
              }
            }
            form.append('title', that.reclamation.title)
            form.append('detail', that.reclamation.detail)
            form.append('reclamationType', that.reclamation.reclamationType.value)
            form.append('reclamationSubType', that.reclamation.reclamationSubType.value)
            form.append('company', that.reclamation.company.id)
            if (that.reclamation.userClient) form.append('userClient', that.reclamation.userClient.id)
            form.append('externalDocNumber', that.reclamation.externalDocNumber)

            if (that.reclamation.contact1) form.append('contact1', that.reclamation.contact1.id)
            if (that.reclamation.contact2) form.append('contact2', that.reclamation.contact2.id)
            that.$store.dispatch('reclamations/insert', form)
              .then(r => {
                that.loading = false
                that.success(r.data)
              })
              .catch(() => {
                this.$swal(
                  {
                    title: `${this.$i18n.t('RECLAMATION_ERROR_POPUP')}`,
                    html: `${this.$i18n.t('RECLAMATION_ERROR_POPUP_TEXT')}.`,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  },
                )
                that.loading = false
              })
          } else {
            that.loading = false
          }
        } else {
          that.loading = false
        }
      })
    },
    success(idReclamation) {
      this.$store.dispatch('reclamations/stats')
      this.$swal(
        {
          title: `${this.$i18n.t('RECLAMATION_CREATED_POPUP_HEADER_1')}${idReclamation} ${this.$i18n.t('RECLAMATION_CREATED_POPUP_HEADER_2')}`,
          html: `${this.$i18n.t('RECLAMATION_POPUP_TEXT_2')}.<br /><br /><small>${this.$i18n.t('RECLAMATION_POPUP_TEXT_3')}.</small>`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        },
      ).then(isConfirm => {
        if (isConfirm) {
          this.selectedCompany = null
          this.reclamation = {
            title: '',
            detail: '',
            company: '',
            externalDocNumber: '',
            userClient: '',
            contact1: '',
            contact2: '',
            reclamationType: '',
            reclamationSubType: '',
            notifiedUser1: '',
            notifiedUser2: '',
          }
          this.reclamationFiles = []
          this.files = []

          this.$router.push({ path: '/reclamation/my/NEW' })
        }
      })
    },
    afterComplete(file) {
      this.reclamationFiles.push(file)
      return true
    },
    afterRemove(file) {
      const files = this.reclamationFiles.filter(fichier => fichier.upload !== file.upload)
      this.reclamationFiles = files
      return true
    },
  },
}
</script>
<style>
.dropzone {
  min-height: 150px;
  border: 1px solid #021738 ;
  background: none;
  position: relative;
  border-radius: 0.357rem;
}
.dz-message {
    font-size: 2rem;
    margin-top:5px;
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    color: #021738 ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.dz-message:before {
    content: '';
    font-size: 80px;
    position: absolute;
    top:0;
    width: 80px;
    height: 80px;
    display: inline-block;
    line-height: 1;
    z-index: 2;
    color: #021738 ;
    text-indent: 0px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}
.dropzone-custom-title{
    font-size: 2rem;
    margin-top:5px;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100%;
    color: #021738 ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.vue-dropzone .dz-preview .dz-details {
  background: none;
}
.dz-progress { display: none; }

</style>
